<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-container class="px-0 d-flex justify-center py-16">
    <CdeCard
      class="d-flex flex-column px-10 py-5"
      :rounded="smAndDown ? '0' : 'lg'"
      color="secondary"
      max-width="100%"
      width="700">
      <v-card-item class="justify-center pb-4">
        <v-card-title class="font-weight-bold text-h4">
          {{ activeTranslation?.title }}
        </v-card-title>
      </v-card-item>

      <v-card-text
        class="text-center font-weight-thin"
        v-html="sanitizeHtml(activeTranslation?.text_content)" />

      <v-card-actions v-if="infobox.show_button" class="justify-center">
        <ButtonPrimary class="infoxbox-btn" @click="scrollTo(infobox.button_destination)">
          {{ activeTranslation?.button_label }}
        </ButtonPrimary>
      </v-card-actions>
    </CdeCard>
  </v-container>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay, useGoTo } from 'vuetify'
import type { Infobox, InfoboxTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const { locale, locales } = useI18n()

const { smAndDown } = useDisplay()
const goTo = useGoTo()

const props = defineProps({
  infobox: {
    type: Object as PropType<Infobox>,
    default: () => ({}),
  },
})

const scrollTo = async (target: string | null) => {
  if (typeof target !== 'string' || target === null) return

  if (!target || target.length === 0 || target.split('#')[1]?.length === 0) {
    goTo(0)
  } else if (target.includes('http') || target.includes('www')) {
    await navigateTo(target, {
      open: {
        target: '_blank',
      },
    })
  } else {
    goTo(target, {
      container: 'html',
      duration: 300,
      easing: 'easeInOutCubic',
      offset: 1,
    })
  }
}

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation = computed(() => {
  return infobox.value.translations?.find(
    (t: InfoboxTranslation) => t.languages_code === currentLocaleIso.value,
  )
})

const { infobox } = toRefs(props)
</script>

<style scoped lang="scss">
:deep(strong) {
  font-weight: 800;
}
</style>
